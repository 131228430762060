@tailwind base;
@tailwind components;
@tailwind utilities;

.fade-in {
    animation: fadeIn 0.5s ease-in;
}

@keyframes fadeIn {
    from { opacity: 0; transform: translateY(10px); }
    to { opacity: 1; transform: translateY(0); }
}

.fade-in {
    animation: fadeIn 0.5s ease-in;
}

.fade-up {
    animation: fadeUp 0.7s ease-out;
}

.glow-text {
    text-shadow: 0 0 10px rgba(255,255,255,0.3);
}

.glow-border {
    box-shadow: 0 2px 10px rgba(255,255,255,0.1);
}

.hover-bright {
    transition: text-shadow 0.3s ease;
}

.hover-bright:hover {
    text-shadow: 0 0 8px rgba(255,255,255,0.2);
}

@keyframes fadeIn {
    from { opacity: 0; transform: translateY(10px); }
    to { opacity: 1; transform: translateY(0); }
}

@keyframes fadeUp {
    from { opacity: 0; transform: translateY(20px); }
    to { opacity: 1; transform: translateY(0); }
}

.fade-in {
    animation: fadeIn 0.5s ease-in;
}

.fade-up {
    animation: fadeUp 0.7s ease-out;
}

.glow-text {
    text-shadow: 0 0 10px rgba(255,255,255,0.3);
}

.glow-border {
    box-shadow: 0 2px 10px rgba(255,255,255,0.1);
}

.hover-bright {
    transition: text-shadow 0.3s ease;
}

.hover-bright:hover {
    text-shadow: 0 0 8px rgba(255,255,255,0.2);
}

@keyframes fadeIn {
    from { opacity: 0; transform: translateY(10px); }
    to { opacity: 1; transform: translateY(0); }
}

@keyframes fadeUp {
    from { opacity: 0; transform: translateY(20px); }
    to { opacity: 1; transform: translateY(0); }
}